import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/home/Home';
import PasteUrl from "./components/pasteUrl/PasteUrl"
import Products from './components/Products/Products';
import Gad from "./components/gad/Gad"

export const routes = {
  index: "/",
  url: "/url",
  products: "/products",
  gad: "/gad",
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.index} component={Gad} />
        <Route exact path={routes.url} component={PasteUrl} />
        <Route exact path={routes.products} component={Products} />
        <Route exact path={routes.gad} component={Gad} />
      </Switch>
    </Router>
  )
}
