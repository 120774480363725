import React,{useRef} from 'react'
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { uploadVideo } from "./service"
import { v4 as uuidv4 } from 'uuid'
import "./index.scss"

export default function Gad() {
    const { width, height } = useWindowDimensions()
    const handleUpload=(e)=>{
        uploadVideo({ video: e.target.files[0], appId: uuidv4() })
    }
    const inputRef=useRef()
    return (
        <div id="gad" style={{ width: `${width}px`, height: `${height}px`,overflowY:"hidden" }}>
            <div className="logo-section">
                <img src="./logo.adc772a5.svg" alt="" />
            </div>
            <div className="description">
                <img src="./camera.png" alt="" />
                <div>
                    <div className="text-1">Take a picture of a flat jumper, preferably:</div>
                    <div className="options">
                        <li>on a neutral background</li>
                        <li>with no significant wrinkles</li>
                        <li>untilted and symmetrical</li>
                    </div>
                    <img src="./image 462.png" alt="" />
                </div>
            </div>
            <input
                hidden
                ref={inputRef}
                type="file"
                accept="video/*"
                capture
                onChange={handleUpload}
            />
            <div className="upload-text">
                <img src="./cloud.png" alt="" />
                <div className="text">Upload here</div>
            </div>
            <div className="btn-container">
                <button className='upload' onClick={() => { inputRef.current.click() }}>Upload</button>
            </div>
            <div className="upload-text">
                <img src="./3dbtn.png" alt="" />
                <div className="text">Convert it to a 3D object</div>
            </div>
           <div className="turn-to-3d-container">
                <button className="turn-to-3d">
                    <img src="./turnTo3d.png" alt="" />
                    <div className="text" style={{color:"white"}}>Turn to 3D</div>
                </button>
           </div>
        </div>
    )
}
