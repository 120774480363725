import { API } from '../../utils/api';
import { ROUTES } from '../../utils/routes';
export const uploadVideo = async  (data) => {
    let { video, appId } = data;
    function onSuccess(response) {
        return true;
    }
    function onError(error) {
        return false;
    }
    try {
        console.log("start")
        let form_data = new FormData();
        form_data.append('app_id', appId);
        form_data.append('video', video);
        const response = await API.post(ROUTES.uploadVideo, form_data);
        return onSuccess(response);
    } catch (error) {
        console.log(error)
        return onError(error);
    }
};