import React from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../App'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import "./index.scss"

export default function PasteUrl() {
    const history=useHistory()
    const handleClick=()=>{
        history.push(routes.products)
    }
    const  {width,height}=useWindowDimensions()
    return (
        <div id="paste-url" style={{ width: `${width}px`, height: `${height}px`,  backgroundImage: `url("./pasteUrlBack.png")` }}>
            <div className="wrapper">
                <input type="text" name="url" id="url" placeholder='Paste link here' />
                <button onClick={handleClick}>
                    <img src="./cubeIcon.png" alt="cubeIcon" />
                    <div>Turn to 3D</div>
                </button>
            </div>
        </div>
    )
}
