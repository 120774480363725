import React, { useState } from 'react'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import "./index.scss"

function PopUp({ active, setshowPopUp }) {
    const { width, height } = useWindowDimensions()
    return (
        <>
            {active && <div id="pop-up" style={{ width: `${width}px`, maxWidth: `${width}px`, minWidth: `${width}px`, height: `${height}px`, minHeight: `${height}px`, maxHeight: `${height}px` }}>
                <div className="wrapper">
                    <div className="p-header">
                        <img src="./readPlayerMe.png" className="ready" alt="" />
                        <img src="./close.svg" alt="" style={{ cursor: "pointer" }} onClick={() => setshowPopUp(false)} />
                    </div>
                    <div className="video-collection">
                        <video src="./jumper.mp4" autoPlay muted loop></video>
                        <video src="./pants.mp4" autoPlay muted loop></video>
                    </div>
                    <button>Dress your RPM avatar</button>
                </div>
            </div>}
        </>
    )
}

export default function Products() {
    const images={
        "blue":"./jeans.png",
        "yellow":"./Chinos.png",
        "gray":"./classic.png"
    }
    const [showPopUp, setshowPopUp] = useState(false)
    const [active, setactive] = useState("gray")
    const { width, height } = useWindowDimensions()
    return (
        <div id="products" style={{ width: `${width}px`, maxWidth: `${width}px`, minWidth: `${width}px`, height: `${height}px`, minHeight: `${height}px`, maxHeight: `${height}px` }}>
            <PopUp active={showPopUp} setshowPopUp={setshowPopUp} />
            <div className="image-in-mirror" style={{ width: `${width}px`, maxWidth: `${width}px`, minWidth: `${width}px` ,backgroundImage: `url("./${images[active]}")` }}>
                <button className="export-to-web3" onClick={() => { setshowPopUp(true)}}>
                    <img src="./globe.png" alt="" />
                    <div className="text">Export to Web3</div>
                </button>
            </div>
            <div className="header-element">
                <div className="header">Complete the look</div>
            </div>
            <div style={{width:"100%",display:"grid",placeItems:"center"}}>
                <div className="products" style={{ width: `${width - 14}px`, maxWidth: `${width - 14}px`, minWidth: `${width - 14}px`, }}>
                    <SingleProduct title="Redefined Rebel
STOCKHOLM - Slim fit jeans" price="£36.99" image="./blue.png" color="blue" active={active} setactive={setactive} />
                    <SingleProduct title="Redefined Rebel
RRERCAN - Chinos" price="
£24.95" image="./yellow.png" color="yellow" active={active} setactive={setactive} />
                    <SingleProduct title="Jack & Jones
Regular Fit" price="£44.99" image="./gray.png" color="gray" active={active} setactive={setactive} />
                </div>
            </div>
            <div className="sizes-selection" style={{ width: `${width}px`, maxWidth: `${width}px`, minWidth: `${width}px`, }}>
                <select name="size" id="size">
                    <option value="s">Select size</option>
                    <option value="s">S</option>
                    <option value="m">M</option>
                    <option value="l">L</option>
                </select>
                <button className="add-to-bag">Add to bag</button>
            </div>
        </div>
    )
}
function SingleProduct({ title, price, image,color,active,setactive }) {
    return <div className="single-product" style={{opacity:`${color===active?1:0.3}`}} onClick={()=>{setactive(color)}}>
        <div className="product-image" style={{ backgroundImage: `url("./${image}")` }}></div>
        <div className="product-deta">
            <div className="product-details">{title}</div>
            <div className="product-price">{price}</div>
        </div>
    </div>
}

