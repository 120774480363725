import { useEffect, useState } from "react";

export const useWindowDimensions=()=>{
    const [dimensions, setdimensions] = useState({
        width:window.innerWidth,
        height:window.innerHeight,
    })
    useEffect(() => {
        window.addEventListener("resize",()=>{
            setdimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        })
    },[])
    if(window.innerWidth>600){
        return { width: Math.min(375, dimensions.width), height: Math.min(700, dimensions.height) }
    }
    return dimensions
}
